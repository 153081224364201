import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import { lowerCapitalize, capitalizeAll } from 'utils/string';
import ANALYTICS from 'constants/analytics';
import ToolTip from 'components/ToolTip';
import TextField from 'components/Form/TextField';
import Button from 'components/Button';
import { utils as globalUtils } from '@ehi/global-marketing-interface';

const DiscountCodeVehicleClass = ({
  readOnlyContract,
  disableCouponCode,
  isSixColumnGrid,
  isPartnerRewards,
  isLmsOrPlanAhedSpecials,
  initialProductCode,
  initialContractCode,
  initialCouponCode,
  initialContractName,
  initialIsLmOrPlanAheadSpecials,
  openRemoveProductCodeModal,
  showProductCode,
  authoredContract,
  clearDiscountInReservationFlow,
  closeModal,
  isDeeplink,
  isTrueModify,
  isDiscountCollapsed,
  setContract,
  setCoupon,
  onExpand,
  onLmOrPlanAheadDiscountRemoval,
  breakpoint,
  disableVCFilter,
  pickupLocationId,
  showVehicleClassFilterModal,
  initialSearchByVehicles,
  vehicle_categories,
  displayingPreservedSessionData,
}) => {
  const [isExpanded, setIsExpanded] = useState(!isDiscountCollapsed);
  const [contractCode, setContractCode] = useState(initialContractCode);
  const [couponCode, setCouponCode] = useState(initialCouponCode);
  const [contractName, setContractName] = useState(initialContractName);
  const [isLmOrPlanAheadSpecialsSession, setIsLmOrPlanAheadSpecialsSession] = useState(initialIsLmOrPlanAheadSpecials); // init state for last minute or plan ahead specials
  const [productCode, setProductCode] = useState(initialProductCode);
  const [isProductCodeReadOnly, setIsProductCodeReadOnly] = useState(false);
  const [searchByVehicleValue, setSearchByVehicles] = useState(initialSearchByVehicles);
  const discountCodeRef = useRef(null);
  const uuid = utils.dom.uuid();
  const { isTablet, isMobile } = breakpoint;
  const lowerCapitalizeAnalyticsKeyForVC = lowerCapitalize(ANALYTICS.FILTER_BY_VEHICLE_CLASS);
  const capitalizeAnalyticsKeyForVC = ANALYTICS.FILTER_BY_VEHICLE_CLASS;

  useEffect(() => {
    !isDiscountCollapsed && setIsExpanded(true);
  }, [isDiscountCollapsed]);

  // Update couponCode
  useEffect(() => {
    setCouponCode(initialCouponCode);
  }, [initialCouponCode]);

  // Update contractCode
  useEffect(() => {
    setContractCode(initialContractCode);
  }, [initialContractCode]);

  // Update contractName
  useEffect(() => {
    setContractName(initialContractName);
  }, [initialContractName]);

  // Update isLmOrPlanAheadSpecialsSession
  useEffect(() => {
    setIsLmOrPlanAheadSpecialsSession(initialIsLmOrPlanAheadSpecials);
  }, [initialIsLmOrPlanAheadSpecials]);

  // Check if contractCode was removed for LMS or Plan Ahead pages
  useEffect(() => {
    if (initialContractCode && (isLmsOrPlanAhedSpecials || initialIsLmOrPlanAheadSpecials) && !contractCode) {
      onLmOrPlanAheadDiscountRemoval(true);
    }
  }, [contractCode]);

  // Update productCode
  useEffect(() => {
    setProductCode(initialProductCode);
  }, [initialProductCode]);

  // Update searchByVehicleValue
  useEffect(() => {
    setSearchByVehicles(initialSearchByVehicles);
    if (!pickupLocationId) {
      setSearchByVehicles(utils.i18n('all_vehicles'));
    }
  }, [initialSearchByVehicles, pickupLocationId]);

  const handleClick = (e, label) => {
    e.stopPropagation();
    const { getNextTabbable } = utils.accessibility;
    const expandButton = e.target;
    const nextTabbable = getNextTabbable(expandButton, discountCodeRef.current);
    const discountCodeTabbableButton = document.querySelectorAll('.discount-code--with-coupon button');

    setIsExpanded(!isExpanded);
    onExpand(!isExpanded);

    if (!isExpanded && nextTabbable) {
      expandButton.focus();
    }

    discountCodeTabbableButton.forEach((tabbable, index) => {
      !isExpanded && index !== 0 ? tabbable.setAttribute('tabindex', '-1') : tabbable.removeAttribute('tabindex');
    });

    if (label === utils.i18n('search_vehicle_class')) {
      utils.analytics.interaction(ANALYTICS.UI_BUTTON, lowerCapitalizeAnalyticsKeyForVC, ANALYTICS.FILTER_OPEN);
    }
  };

  const handleContractChange = (e) => {
    const { value } = e.target;
    setContractCode(value);
    utils.safeExecute(setContract, value);
  };

  const handleVehicleFilter = () => {
    showVehicleClassFilterModal();

    utils.analytics.interaction(
      capitalizeAll(ANALYTICS.MODAL),
      ANALYTICS.FILTER_BY_VEHICLE_CLASS,
      capitalizeAll(ANALYTICS.OPEN)
    );
  };

  const handleFilterChange = (e) => {
    e.stopPropagation();
  };

  const handleCouponChange = (e) => {
    const { value } = e.target;
    setCouponCode(value);
    utils.safeExecute(setCoupon, value);
  };

  const onConfirmRemoveProductCode = useCallback(
    (clearInitialValue) => {
      clearInitialValue();
      setProductCode('');
      closeModal({ analyticsValue: utils.i18n('progress_bar_cid_remove_modal_accept') });
      setIsProductCodeReadOnly(true);
      clearDiscountInReservationFlow({ product_code: true });
    },
    [closeModal, clearDiscountInReservationFlow]
  );

  const removeProductCode = useCallback(
    (clearInitialValue) => {
      openRemoveProductCodeModal(productCode, () => onConfirmRemoveProductCode(clearInitialValue));
    },
    [openRemoveProductCodeModal, onConfirmRemoveProductCode, productCode]
  );

  const renderLabelDiscountCode = () =>
    disableCouponCode ? (
      <>
        {!isExpanded && (
          <Button
            type='button'
            className='discount-code__toggle-button'
            onClick={handleClick}
            id={`cid-toggle-button-${uuid}`}
            link
            ariaExpanded-={isExpanded.toString()}
            ariaText={utils.i18n('add_discount_code')}
          />
        )}
        <label className='discount-code__label' htmlFor={isExpanded ? uuid : `cid-toggle-button-${uuid}`}>
          {utils.i18n(isExpanded ? 'discount_code' : 'add_discount_code')}{' '}
        </label>
      </>
    ) : (
      <>
        <Button
          type='button'
          className={cn('discount-code__toggle-button', { 'discount-code__toggle-button--collapsable': isExpanded })}
          onClick={handleClick}
          id={`cid-toggle-button-${uuid}`}
          link
          ariaExpanded={isExpanded.toString()}
          ariaText={utils.i18n('add_discount_code')}
        />
        <label className='discount-code__label' htmlFor={`cid-toggle-button-${uuid}`}>
          {utils.i18n('discount_codes')}{' '}
        </label>
      </>
    );

  const renderLabelVehicleClass = () => (
    <>
      <Button
        type='button'
        className='discount-code__toggle-button-vehicle'
        onClick={(e) => handleClick(e, utils.i18n('search_vehicle_class'))}
        id={`vehicle-class-toggle-button-${uuid}`}
        link
        disabled={!pickupLocationId || disableVCFilter || isTrueModify}
      />
      <label className='discount-code__label' htmlFor={`vehicle-class-toggle-button-${uuid}`}>
        {utils.i18n('search_vehicle_class')}{' '}
      </label>
    </>
  );

  const renderCouponField = () => (
    <TextField
      id={`coupon_${uuid}`}
      name='couponCode'
      label={utils.i18n('coupon_code_label')}
      fill
      initialValueButton={isDeeplink && couponCode}
      initialValueButtonTracking={utils.analytics.dtm(ANALYTICS.RES_WIDGET, ANALYTICS.COUPON, couponCode)}
      value={couponCode}
      onChange={handleCouponChange}
      readOnly={!!(contractCode && (isLmsOrPlanAhedSpecials || isLmOrPlanAheadSpecialsSession))}
      autoComplete='off'
      aria-autocomplete='none'
      aria-haspopup='false'
    />
  );

  const renderProductCodeField = () => (
    <TextField
      id={`product_code_${uuid}`}
      name='productCode'
      label={utils.i18n('product_code_label')}
      fill
      initialValueButton={productCode}
      initialValueButtonHandler={removeProductCode}
      value={productCode}
      readOnly={isProductCodeReadOnly || (!productCode && showProductCode)}
    />
  );

  const getTooltipCopy = useCallback(() => {
    if (isTrueModify) {
      return utils.i18n('true_modify_booking_widget_discount_code_tooltip');
    }

    if (isLmsOrPlanAhedSpecials || isLmOrPlanAheadSpecialsSession) {
      return utils.i18n('lms_discount_code_tooltip');
    }

    return utils.i18n('discount_code_tooltip');
  }, [isTrueModify, isLmsOrPlanAhedSpecials]);

  // one_way_rental prop to determine the correct tooltip
  const getVehicleTooltip = () => {
    if (disableVCFilter) {
      return utils.i18n('filter_vehicle_class_tooltip_multiple_locations');
    }
    if (!pickupLocationId) {
      return utils.i18n('filter_vehicle_class_tooltip_multiple_locations');
    }

    return utils.i18n('filter_vehicle_class_tooltip_default');
  };

  // Checking to determine if there are options available for Vehicle Class Filtering
  const areCategoriesBlank = () => !globalUtils.isArrayNotEmpty(vehicle_categories.flatMap((item) => item.vehicles));

  let initialCIDValueButton = contractCode && (authoredContract?.contractDetails?.contractName || contractName);
  if (isDeeplink) {
    initialCIDValueButton = displayingPreservedSessionData
      ? contractCode && authoredContract?.contractDetails?.contractName
      : contractCode;
  }

  return (
    <div
      className={cn('discount-code', {
        'discount-code--expanded': isExpanded,
        'discount-code--with-coupon': !disableCouponCode,
        'discount-code--six-column-grid': isSixColumnGrid,
        'product-code--with-coupon': !!productCode || showProductCode,
      })}
      ref={discountCodeRef}
    >
      <span
        className={cn('discount-code__label-wrapper', {
          'discount-code__label-wrapper-tablet': isTablet,
        })}
      >
        {/* Show discount inputs on tablet without it being collapsed */}
        {isTablet ? (
          <span className='discount-code__label'>{utils.i18n('discount_code')}</span>
        ) : (
          renderLabelDiscountCode()
        )}
        <span className='discount-code__tooltip-break'>
          <ToolTip
            placement='left-end'
            triggerTitle={utils.i18n('discount_code_tooltip_trigger_label')}
            analyticsKey={capitalizeAnalyticsKeyForVC}
          >
            <label>{getTooltipCopy()}</label>
          </ToolTip>
        </span>
      </span>
      {/* Start of Vechile Class CTA */}
      {!isMobile && (
        <span
          className={cn('vehicle-class__label-wrapper', {
            'discount-code__label-wrapper-tablet': isTablet,
            'vehicle-class__label-wrapper-disabled': !pickupLocationId || disableVCFilter || isTrueModify,
          })}
        >
          {/* Show discount inputs on tablet without it being collapsed */}
          {isTablet ? (
            <span className='discount-code__label'>{utils.i18n('search_vehicle_class')}</span>
          ) : (
            renderLabelVehicleClass()
          )}
          <span className='discount-code__tooltip-break vehicle-class_tooltip'>
            <ToolTip placement='left-end' analyticsKey={capitalizeAnalyticsKeyForVC}>
              <label>{getVehicleTooltip()}</label>
            </ToolTip>
          </span>
        </span>
      )}

      {(isExpanded || isTablet) && (
        <>
          {(!!productCode || showProductCode) && renderProductCodeField()}
          {!disableCouponCode &&
            !isPartnerRewards &&
            !isLmsOrPlanAhedSpecials &&
            !isLmOrPlanAheadSpecialsSession &&
            renderCouponField()}

          <TextField
            id={uuid}
            name='contractNumber'
            label={utils.i18n('discount_code_label')}
            fill
            initialValueButton={initialCIDValueButton}
            initialValueButtonTracking={utils.analytics.dtm(
              ANALYTICS.RES_WIDGET,
              ANALYTICS.CONTRACT,
              contractCode && (authoredContract?.contractDetails?.contractName || contractName),
              contractCode
            )}
            value={contractCode}
            onChange={handleContractChange}
            readOnly={readOnlyContract}
            autoComplete='off'
            aria-autocomplete='none'
            aria-haspopup='false'
          />

          {!disableCouponCode &&
            (isPartnerRewards || isLmsOrPlanAhedSpecials || isLmOrPlanAheadSpecialsSession) &&
            renderCouponField()}

          {!isMobile && (
            <TextField
              className='field-control--coursor-pointer'
              readOnly={!pickupLocationId || disableVCFilter || areCategoriesBlank() || isTrueModify}
              fill
              name='vehicleClass'
              label={utils.i18n('search_vehicle_class')}
              input={{ value: searchByVehicleValue, onChange: handleVehicleFilter }}
              onClick={handleVehicleFilter}
              showDropdownRightIcon={true}
            />
          )}
        </>
      )}
      {isMobile && (
        <>
          <span
            className={cn('vehicle-class__label-wrapper', {
              'vehicle-class__label-wrapper-disabled': !pickupLocationId || disableVCFilter || isTrueModify,
            })}
          >
            {renderLabelVehicleClass()}
            <span className='discount-code__tooltip-break'>
              <ToolTip placement='left-end' analyticsKey={capitalizeAnalyticsKeyForVC}>
                <label>{getVehicleTooltip()}</label>
              </ToolTip>
            </span>
          </span>

          {isExpanded && (
            <TextField
              className='field-control--coursor-pointer'
              readOnly={!pickupLocationId || disableVCFilter || areCategoriesBlank() || isTrueModify}
              fill
              name='vehicleClass'
              label={utils.i18n('search_vehicle_class')}
              input={{ value: searchByVehicleValue, onChange: handleFilterChange }}
              onClick={handleVehicleFilter}
              showDropdownRightIcon={true}
            />
          )}
        </>
      )}
    </div>
  );
};

DiscountCodeVehicleClass.propTypes = {
  isDiscountCollapsed: PropTypes.bool,
  onExpand: PropTypes.func,
  setContract: PropTypes.func.isRequired,
  readOnlyContract: PropTypes.bool,
  isLmsOrPlanAhedSpecials: PropTypes.bool,
  setCoupon: PropTypes.func,
  disableCouponCode: PropTypes.bool,
  isSixColumnGrid: PropTypes.bool,
  isPartnerRewards: PropTypes.bool,
  showProductCode: PropTypes.bool,
  initialProductCode: PropTypes.string,
  initialContractCode: PropTypes.string,
  initialCouponCode: PropTypes.string,
  openRemoveProductCodeModal: PropTypes.func,
  setProductCode: PropTypes.func,
  clearDiscountInReservationFlow: PropTypes.func,
  closeModal: PropTypes.func,
  authoredContract: PropTypes.object,
  isDeeplink: PropTypes.bool,
  isTrueModify: PropTypes.bool,
  disableVCFilter: PropTypes.bool,
  pickupLocationId: PropTypes.string,
  initialSearchByVehicles: PropTypes.string,
  displayingPreservedSessionData: PropTypes.bool,
};

DiscountCodeVehicleClass.defaultProps = {
  disableVCFilter: true,
};

export default DiscountCodeVehicleClass;
